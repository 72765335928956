import list from './countries-list';
const countriesHelper = {
  /**
   * Retrieving the list of countries grouped by continent
   */
  getGroupedContries() {
    const countriesContinent = [];
    const continentList = list.map(item => item.Continent_Name);
    const continentListNoDouble = [...new Set(continentList)];
    const menaCountries = ['AE', 'SA', 'EG', 'QA'];

    // Extracting mena countries and sort them based on menaCountries array.
    // Those countries will be listed on top of the others without the continent group
    const _menaCountries = list
      .filter(item => menaCountries.indexOf(item.Two_Letter_Country_Code) !== -1)
      .sort(function (a, b) {
        return menaCountries.indexOf(a.Two_Letter_Country_Code) - menaCountries.indexOf(b.Two_Letter_Country_Code);
      });
    // Mapping mena countries as needed for select component
    const mappedMenaCountries = _menaCountries.map(country => ({
      label: country.Country_Name,
      value: country.Two_Letter_Country_Code,
      itemProps: {
        clickable: true,
        manualFocus: true,
        focused: false
      }
    }));

    // Pushing top level countries
    countriesContinent.push({
      group: '',
      disabled: true,
      labels: mappedMenaCountries
    });

    /**
     * Looping thorugh every available continent
     */
    for (const continent of continentListNoDouble) {
      // Extracting countries of that continent
      const countries = list.filter(item => item.Continent_Name === continent && menaCountries.indexOf(item.Two_Letter_Country_Code) === -1);
      // Mapping every country to have label, value and select info
      let mappedCountries = countries.map(country => ({
        label: country.Country_Name,
        value: country.Two_Letter_Country_Code,
        itemProps: {
          clickable: true,
          manualFocus: true,
          focused: false
        }
      }));

      countriesContinent.push({
        group: continent,
        disabled: true,
        labels: mappedCountries
      });
    }

    return countriesContinent;
  }
};

export default countriesHelper;
