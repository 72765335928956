/* eslint-disable quotes */
const list = [
  { Continent_Code: 'AS', Continent_Name: 'Asia', Country_Name: 'Afghanistan', Country_Number: 4, Three_Letter_Country_Code: 'AFG', Two_Letter_Country_Code: 'AF' },
  { Continent_Code: 'EU', Continent_Name: 'Europe', Country_Name: 'Albania', Country_Number: 8, Three_Letter_Country_Code: 'ALB', Two_Letter_Country_Code: 'AL' },
  {
    Continent_Code: 'AN',
    Continent_Name: 'Antarctica',
    Country_Name: 'Antarctica',
    Country_Number: 10,
    Three_Letter_Country_Code: 'ATA',
    Two_Letter_Country_Code: 'AQ'
  },
  {
    Continent_Code: 'AF',
    Continent_Name: 'Africa',
    Country_Name: 'Algeria',
    Country_Number: 12,
    Three_Letter_Country_Code: 'DZA',
    Two_Letter_Country_Code: 'DZ'
  },
  { Continent_Code: 'OC', Continent_Name: 'Oceania', Country_Name: 'American Samoa', Country_Number: 16, Three_Letter_Country_Code: 'ASM', Two_Letter_Country_Code: 'AS' },
  { Continent_Code: 'EU', Continent_Name: 'Europe', Country_Name: 'Andorra', Country_Number: 20, Three_Letter_Country_Code: 'AND', Two_Letter_Country_Code: 'AD' },
  { Continent_Code: 'AF', Continent_Name: 'Africa', Country_Name: 'Angola', Country_Number: 24, Three_Letter_Country_Code: 'AGO', Two_Letter_Country_Code: 'AO' },
  { Continent_Code: 'NA', Continent_Name: 'North America', Country_Name: 'Antigua and Barbuda', Country_Number: 28, Three_Letter_Country_Code: 'ATG', Two_Letter_Country_Code: 'AG' },
  { Continent_Code: 'EU', Continent_Name: 'Europe', Country_Name: 'Azerbaijan', Country_Number: 31, Three_Letter_Country_Code: 'AZE', Two_Letter_Country_Code: 'AZ' },
  { Continent_Code: 'AS', Continent_Name: 'Asia', Country_Name: 'Azerbaijan', Country_Number: 31, Three_Letter_Country_Code: 'AZE', Two_Letter_Country_Code: 'AZ' },
  {
    Continent_Code: 'SA',
    Continent_Name: 'South America',
    Country_Name: 'Argentina',
    Country_Number: 32,
    Three_Letter_Country_Code: 'ARG',
    Two_Letter_Country_Code: 'AR'
  },
  { Continent_Code: 'OC', Continent_Name: 'Oceania', Country_Name: 'Australia', Country_Number: 36, Three_Letter_Country_Code: 'AUS', Two_Letter_Country_Code: 'AU' },
  { Continent_Code: 'EU', Continent_Name: 'Europe', Country_Name: 'Austria', Country_Number: 40, Three_Letter_Country_Code: 'AUT', Two_Letter_Country_Code: 'AT' },
  {
    Continent_Code: 'NA',
    Continent_Name: 'North America',
    Country_Name: 'Bahamas',
    Country_Number: 44,
    Three_Letter_Country_Code: 'BHS',
    Two_Letter_Country_Code: 'BS'
  },
  { Continent_Code: 'AS', Continent_Name: 'Asia', Country_Name: 'Bahrain', Country_Number: 48, Three_Letter_Country_Code: 'BHR', Two_Letter_Country_Code: 'BH' },
  { Continent_Code: 'AS', Continent_Name: 'Asia', Country_Name: 'Bangladesh', Country_Number: 50, Three_Letter_Country_Code: 'BGD', Two_Letter_Country_Code: 'BD' },
  { Continent_Code: 'EU', Continent_Name: 'Europe', Country_Name: 'Armenia', Country_Number: 51, Three_Letter_Country_Code: 'ARM', Two_Letter_Country_Code: 'AM' },
  { Continent_Code: 'AS', Continent_Name: 'Asia', Country_Name: 'Armenia', Country_Number: 51, Three_Letter_Country_Code: 'ARM', Two_Letter_Country_Code: 'AM' },
  { Continent_Code: 'NA', Continent_Name: 'North America', Country_Name: 'Barbados', Country_Number: 52, Three_Letter_Country_Code: 'BRB', Two_Letter_Country_Code: 'BB' },
  { Continent_Code: 'EU', Continent_Name: 'Europe', Country_Name: 'Belgium', Country_Number: 56, Three_Letter_Country_Code: 'BEL', Two_Letter_Country_Code: 'BE' },
  { Continent_Code: 'NA', Continent_Name: 'North America', Country_Name: 'Bermuda', Country_Number: 60, Three_Letter_Country_Code: 'BMU', Two_Letter_Country_Code: 'BM' },
  { Continent_Code: 'AS', Continent_Name: 'Asia', Country_Name: 'Bhutan', Country_Number: 64, Three_Letter_Country_Code: 'BTN', Two_Letter_Country_Code: 'BT' },
  { Continent_Code: 'SA', Continent_Name: 'South America', Country_Name: 'Bolivia', Country_Number: 68, Three_Letter_Country_Code: 'BOL', Two_Letter_Country_Code: 'BO' },
  { Continent_Code: 'EU', Continent_Name: 'Europe', Country_Name: 'Bosnia and Herzegovina', Country_Number: 70, Three_Letter_Country_Code: 'BIH', Two_Letter_Country_Code: 'BA' },
  { Continent_Code: 'AF', Continent_Name: 'Africa', Country_Name: 'Botswana', Country_Number: 72, Three_Letter_Country_Code: 'BWA', Two_Letter_Country_Code: 'BW' },
  { Continent_Code: 'AN', Continent_Name: 'Antarctica', Country_Name: 'Bouvet Island (Bouvetoya)', Country_Number: 74, Three_Letter_Country_Code: 'BVT', Two_Letter_Country_Code: 'BV' },
  {
    Continent_Code: 'SA',
    Continent_Name: 'South America',
    Country_Name: 'Brazil',
    Country_Number: 76,
    Three_Letter_Country_Code: 'BRA',
    Two_Letter_Country_Code: 'BR'
  },
  { Continent_Code: 'NA', Continent_Name: 'North America', Country_Name: 'Belize', Country_Number: 84, Three_Letter_Country_Code: 'BLZ', Two_Letter_Country_Code: 'BZ' },
  {
    Continent_Code: 'AS',
    Continent_Name: 'Asia',
    Country_Name: 'British Indian Ocean Territory (Chagos Archipelago)',
    Country_Number: 86,
    Three_Letter_Country_Code: 'IOT',
    Two_Letter_Country_Code: 'IO'
  },
  { Continent_Code: 'OC', Continent_Name: 'Oceania', Country_Name: 'Solomon Islands', Country_Number: 90, Three_Letter_Country_Code: 'SLB', Two_Letter_Country_Code: 'SB' },
  { Continent_Code: 'NA', Continent_Name: 'North America', Country_Name: 'British Virgin Islands', Country_Number: 92, Three_Letter_Country_Code: 'VGB', Two_Letter_Country_Code: 'VG' },
  { Continent_Code: 'AS', Continent_Name: 'Asia', Country_Name: 'Brunei Darussalam', Country_Number: 96, Three_Letter_Country_Code: 'BRN', Two_Letter_Country_Code: 'BN' },
  { Continent_Code: 'EU', Continent_Name: 'Europe', Country_Name: 'Bulgaria', Country_Number: 100, Three_Letter_Country_Code: 'BGR', Two_Letter_Country_Code: 'BG' },
  { Continent_Code: 'AS', Continent_Name: 'Asia', Country_Name: 'Myanmar', Country_Number: 104, Three_Letter_Country_Code: 'MMR', Two_Letter_Country_Code: 'MM' },
  { Continent_Code: 'AF', Continent_Name: 'Africa', Country_Name: 'Burundi', Country_Number: 108, Three_Letter_Country_Code: 'BDI', Two_Letter_Country_Code: 'BI' },
  { Continent_Code: 'EU', Continent_Name: 'Europe', Country_Name: 'Belarus', Country_Number: 112, Three_Letter_Country_Code: 'BLR', Two_Letter_Country_Code: 'BY' },
  { Continent_Code: 'AS', Continent_Name: 'Asia', Country_Name: 'Cambodia', Country_Number: 116, Three_Letter_Country_Code: 'KHM', Two_Letter_Country_Code: 'KH' },
  { Continent_Code: 'AF', Continent_Name: 'Africa', Country_Name: 'Cameroon', Country_Number: 120, Three_Letter_Country_Code: 'CMR', Two_Letter_Country_Code: 'CM' },
  { Continent_Code: 'NA', Continent_Name: 'North America', Country_Name: 'Canada', Country_Number: 124, Three_Letter_Country_Code: 'CAN', Two_Letter_Country_Code: 'CA' },
  { Continent_Code: 'AF', Continent_Name: 'Africa', Country_Name: 'Cape Verde', Country_Number: 132, Three_Letter_Country_Code: 'CPV', Two_Letter_Country_Code: 'CV' },
  { Continent_Code: 'NA', Continent_Name: 'North America', Country_Name: 'Cayman Islands', Country_Number: 136, Three_Letter_Country_Code: 'CYM', Two_Letter_Country_Code: 'KY' },
  { Continent_Code: 'AF', Continent_Name: 'Africa', Country_Name: 'Central African Republic', Country_Number: 140, Three_Letter_Country_Code: 'CAF', Two_Letter_Country_Code: 'CF' },
  {
    Continent_Code: 'AS',
    Continent_Name: 'Asia',
    Country_Name: 'Sri Lanka',
    Country_Number: 144,
    Three_Letter_Country_Code: 'LKA',
    Two_Letter_Country_Code: 'LK'
  },
  { Continent_Code: 'AF', Continent_Name: 'Africa', Country_Name: 'Chad', Country_Number: 148, Three_Letter_Country_Code: 'TCD', Two_Letter_Country_Code: 'TD' },
  { Continent_Code: 'SA', Continent_Name: 'South America', Country_Name: 'Chile', Country_Number: 152, Three_Letter_Country_Code: 'CHL', Two_Letter_Country_Code: 'CL' },
  { Continent_Code: 'AS', Continent_Name: 'Asia', Country_Name: 'China', Country_Number: 156, Three_Letter_Country_Code: 'CHN', Two_Letter_Country_Code: 'CN' },
  { Continent_Code: 'AS', Continent_Name: 'Asia', Country_Name: 'Taiwan', Country_Number: 158, Three_Letter_Country_Code: 'TWN', Two_Letter_Country_Code: 'TW' },
  { Continent_Code: 'AS', Continent_Name: 'Asia', Country_Name: 'Christmas Island', Country_Number: 162, Three_Letter_Country_Code: 'CXR', Two_Letter_Country_Code: 'CX' },
  { Continent_Code: 'AS', Continent_Name: 'Asia', Country_Name: 'Cocos (Keeling) Islands', Country_Number: 166, Three_Letter_Country_Code: 'CCK', Two_Letter_Country_Code: 'CC' },
  { Continent_Code: 'SA', Continent_Name: 'South America', Country_Name: 'Colombia', Country_Number: 170, Three_Letter_Country_Code: 'COL', Two_Letter_Country_Code: 'CO' },
  { Continent_Code: 'AF', Continent_Name: 'Africa', Country_Name: 'Comoros', Country_Number: 174, Three_Letter_Country_Code: 'COM', Two_Letter_Country_Code: 'KM' },
  { Continent_Code: 'AF', Continent_Name: 'Africa', Country_Name: 'Mayotte', Country_Number: 175, Three_Letter_Country_Code: 'MYT', Two_Letter_Country_Code: 'YT' },
  { Continent_Code: 'AF', Continent_Name: 'Africa', Country_Name: 'Congo', Country_Number: 178, Three_Letter_Country_Code: 'COG', Two_Letter_Country_Code: 'CG' },
  {
    Continent_Code: 'AF',
    Continent_Name: 'Africa',
    Country_Name: 'Congo',
    Country_Number: 180,
    Three_Letter_Country_Code: 'COD',
    Two_Letter_Country_Code: 'CD'
  },
  { Continent_Code: 'OC', Continent_Name: 'Oceania', Country_Name: 'Cook Islands', Country_Number: 184, Three_Letter_Country_Code: 'COK', Two_Letter_Country_Code: 'CK' },
  { Continent_Code: 'NA', Continent_Name: 'North America', Country_Name: 'Costa Rica', Country_Number: 188, Three_Letter_Country_Code: 'CRI', Two_Letter_Country_Code: 'CR' },
  { Continent_Code: 'EU', Continent_Name: 'Europe', Country_Name: 'Croatia', Country_Number: 191, Three_Letter_Country_Code: 'HRV', Two_Letter_Country_Code: 'HR' },
  { Continent_Code: 'NA', Continent_Name: 'North America', Country_Name: 'Cuba', Country_Number: 192, Three_Letter_Country_Code: 'CUB', Two_Letter_Country_Code: 'CU' },
  { Continent_Code: 'EU', Continent_Name: 'Europe', Country_Name: 'Cyprus', Country_Number: 196, Three_Letter_Country_Code: 'CYP', Two_Letter_Country_Code: 'CY' },
  { Continent_Code: 'AS', Continent_Name: 'Asia', Country_Name: 'Cyprus', Country_Number: 196, Three_Letter_Country_Code: 'CYP', Two_Letter_Country_Code: 'CY' },
  { Continent_Code: 'EU', Continent_Name: 'Europe', Country_Name: 'Czech Republic', Country_Number: 203, Three_Letter_Country_Code: 'CZE', Two_Letter_Country_Code: 'CZ' },
  { Continent_Code: 'AF', Continent_Name: 'Africa', Country_Name: 'Benin', Country_Number: 204, Three_Letter_Country_Code: 'BEN', Two_Letter_Country_Code: 'BJ' },
  { Continent_Code: 'EU', Continent_Name: 'Europe', Country_Name: 'Denmark', Country_Number: 208, Three_Letter_Country_Code: 'DNK', Two_Letter_Country_Code: 'DK' },
  {
    Continent_Code: 'NA',
    Continent_Name: 'North America',
    Country_Name: 'Dominica',
    Country_Number: 212,
    Three_Letter_Country_Code: 'DMA',
    Two_Letter_Country_Code: 'DM'
  },
  { Continent_Code: 'NA', Continent_Name: 'North America', Country_Name: 'Dominican Republic', Country_Number: 214, Three_Letter_Country_Code: 'DOM', Two_Letter_Country_Code: 'DO' },
  { Continent_Code: 'SA', Continent_Name: 'South America', Country_Name: 'Ecuador', Country_Number: 218, Three_Letter_Country_Code: 'ECU', Two_Letter_Country_Code: 'EC' },
  { Continent_Code: 'NA', Continent_Name: 'North America', Country_Name: 'El Salvador', Country_Number: 222, Three_Letter_Country_Code: 'SLV', Two_Letter_Country_Code: 'SV' },
  { Continent_Code: 'AF', Continent_Name: 'Africa', Country_Name: 'Equatorial Guinea', Country_Number: 226, Three_Letter_Country_Code: 'GNQ', Two_Letter_Country_Code: 'GQ' },
  {
    Continent_Code: 'AF',
    Continent_Name: 'Africa',
    Country_Name: 'Ethiopia',
    Country_Number: 231,
    Three_Letter_Country_Code: 'ETH',
    Two_Letter_Country_Code: 'ET'
  },
  { Continent_Code: 'AF', Continent_Name: 'Africa', Country_Name: 'Eritrea', Country_Number: 232, Three_Letter_Country_Code: 'ERI', Two_Letter_Country_Code: 'ER' },
  { Continent_Code: 'EU', Continent_Name: 'Europe', Country_Name: 'Estonia', Country_Number: 233, Three_Letter_Country_Code: 'EST', Two_Letter_Country_Code: 'EE' },
  { Continent_Code: 'EU', Continent_Name: 'Europe', Country_Name: 'Faroe Islands', Country_Number: 234, Three_Letter_Country_Code: 'FRO', Two_Letter_Country_Code: 'FO' },
  {
    Continent_Code: 'SA',
    Continent_Name: 'South America',
    Country_Name: 'Falkland Islands (Malvinas)',
    Country_Number: 238,
    Three_Letter_Country_Code: 'FLK',
    Two_Letter_Country_Code: 'FK'
  },
  {
    Continent_Code: 'AN',
    Continent_Name: 'Antarctica',
    Country_Name: 'South Georgia and the South Sandwich Islands',
    Country_Number: 239,
    Three_Letter_Country_Code: 'SGS',
    Two_Letter_Country_Code: 'GS'
  },
  {
    Continent_Code: 'OC',
    Continent_Name: 'Oceania',
    Country_Name: 'Fiji the Fiji Islands',
    Country_Number: 242,
    Three_Letter_Country_Code: 'FJI',
    Two_Letter_Country_Code: 'FJ'
  },
  { Continent_Code: 'EU', Continent_Name: 'Europe', Country_Name: 'Finland', Country_Number: 246, Three_Letter_Country_Code: 'FIN', Two_Letter_Country_Code: 'FI' },
  { Continent_Code: 'EU', Continent_Name: 'Europe', Country_Name: '\u00c5land Islands', Country_Number: 248, Three_Letter_Country_Code: 'ALA', Two_Letter_Country_Code: 'AX' },
  { Continent_Code: 'EU', Continent_Name: 'Europe', Country_Name: 'France', Country_Number: 250, Three_Letter_Country_Code: 'FRA', Two_Letter_Country_Code: 'FR' },
  { Continent_Code: 'SA', Continent_Name: 'South America', Country_Name: 'French Guiana', Country_Number: 254, Three_Letter_Country_Code: 'GUF', Two_Letter_Country_Code: 'GF' },
  { Continent_Code: 'OC', Continent_Name: 'Oceania', Country_Name: 'French Polynesia', Country_Number: 258, Three_Letter_Country_Code: 'PYF', Two_Letter_Country_Code: 'PF' },
  { Continent_Code: 'AN', Continent_Name: 'Antarctica', Country_Name: 'French Southern Territories', Country_Number: 260, Three_Letter_Country_Code: 'ATF', Two_Letter_Country_Code: 'TF' },
  { Continent_Code: 'AF', Continent_Name: 'Africa', Country_Name: 'Djibouti', Country_Number: 262, Three_Letter_Country_Code: 'DJI', Two_Letter_Country_Code: 'DJ' },
  { Continent_Code: 'AF', Continent_Name: 'Africa', Country_Name: 'Gabon', Country_Number: 266, Three_Letter_Country_Code: 'GAB', Two_Letter_Country_Code: 'GA' },
  { Continent_Code: 'EU', Continent_Name: 'Europe', Country_Name: 'Georgia', Country_Number: 268, Three_Letter_Country_Code: 'GEO', Two_Letter_Country_Code: 'GE' },
  { Continent_Code: 'AS', Continent_Name: 'Asia', Country_Name: 'Georgia', Country_Number: 268, Three_Letter_Country_Code: 'GEO', Two_Letter_Country_Code: 'GE' },
  { Continent_Code: 'AF', Continent_Name: 'Africa', Country_Name: 'Gambia', Country_Number: 270, Three_Letter_Country_Code: 'GMB', Two_Letter_Country_Code: 'GM' },
  { Continent_Code: 'AS', Continent_Name: 'Asia', Country_Name: 'Palestinian Territory', Country_Number: 275, Three_Letter_Country_Code: 'PSE', Two_Letter_Country_Code: 'PS' },
  { Continent_Code: 'EU', Continent_Name: 'Europe', Country_Name: 'Germany', Country_Number: 276, Three_Letter_Country_Code: 'DEU', Two_Letter_Country_Code: 'DE' },
  { Continent_Code: 'AF', Continent_Name: 'Africa', Country_Name: 'Ghana', Country_Number: 288, Three_Letter_Country_Code: 'GHA', Two_Letter_Country_Code: 'GH' },
  { Continent_Code: 'EU', Continent_Name: 'Europe', Country_Name: 'Gibraltar', Country_Number: 292, Three_Letter_Country_Code: 'GIB', Two_Letter_Country_Code: 'GI' },
  { Continent_Code: 'OC', Continent_Name: 'Oceania', Country_Name: 'Kiribati', Country_Number: 296, Three_Letter_Country_Code: 'KIR', Two_Letter_Country_Code: 'KI' },
  { Continent_Code: 'EU', Continent_Name: 'Europe', Country_Name: 'Greece', Country_Number: 300, Three_Letter_Country_Code: 'GRC', Two_Letter_Country_Code: 'GR' },
  { Continent_Code: 'NA', Continent_Name: 'North America', Country_Name: 'Greenland', Country_Number: 304, Three_Letter_Country_Code: 'GRL', Two_Letter_Country_Code: 'GL' },
  { Continent_Code: 'NA', Continent_Name: 'North America', Country_Name: 'Grenada', Country_Number: 308, Three_Letter_Country_Code: 'GRD', Two_Letter_Country_Code: 'GD' },
  { Continent_Code: 'NA', Continent_Name: 'North America', Country_Name: 'Guadeloupe', Country_Number: 312, Three_Letter_Country_Code: 'GLP', Two_Letter_Country_Code: 'GP' },
  { Continent_Code: 'OC', Continent_Name: 'Oceania', Country_Name: 'Guam', Country_Number: 316, Three_Letter_Country_Code: 'GUM', Two_Letter_Country_Code: 'GU' },
  { Continent_Code: 'NA', Continent_Name: 'North America', Country_Name: 'Guatemala', Country_Number: 320, Three_Letter_Country_Code: 'GTM', Two_Letter_Country_Code: 'GT' },
  { Continent_Code: 'AF', Continent_Name: 'Africa', Country_Name: 'Guinea', Country_Number: 324, Three_Letter_Country_Code: 'GIN', Two_Letter_Country_Code: 'GN' },
  {
    Continent_Code: 'SA',
    Continent_Name: 'South America',
    Country_Name: 'Guyana',
    Country_Number: 328,
    Three_Letter_Country_Code: 'GUY',
    Two_Letter_Country_Code: 'GY'
  },
  { Continent_Code: 'NA', Continent_Name: 'North America', Country_Name: 'Haiti', Country_Number: 332, Three_Letter_Country_Code: 'HTI', Two_Letter_Country_Code: 'HT' },
  {
    Continent_Code: 'AN',
    Continent_Name: 'Antarctica',
    Country_Name: 'Heard Island and McDonald Islands',
    Country_Number: 334,
    Three_Letter_Country_Code: 'HMD',
    Two_Letter_Country_Code: 'HM'
  },
  { Continent_Code: 'EU', Continent_Name: 'Europe', Country_Name: 'Holy See (Vatican City State)', Country_Number: 336, Three_Letter_Country_Code: 'VAT', Two_Letter_Country_Code: 'VA' },
  { Continent_Code: 'NA', Continent_Name: 'North America', Country_Name: 'Honduras', Country_Number: 340, Three_Letter_Country_Code: 'HND', Two_Letter_Country_Code: 'HN' },
  {
    Continent_Code: 'AS',
    Continent_Name: 'Asia',
    Country_Name: 'Hong Kong',
    Country_Number: 344,
    Three_Letter_Country_Code: 'HKG',
    Two_Letter_Country_Code: 'HK'
  },
  { Continent_Code: 'EU', Continent_Name: 'Europe', Country_Name: 'Hungary', Country_Number: 348, Three_Letter_Country_Code: 'HUN', Two_Letter_Country_Code: 'HU' },
  { Continent_Code: 'EU', Continent_Name: 'Europe', Country_Name: 'Iceland', Country_Number: 352, Three_Letter_Country_Code: 'ISL', Two_Letter_Country_Code: 'IS' },
  { Continent_Code: 'AS', Continent_Name: 'Asia', Country_Name: 'India', Country_Number: 356, Three_Letter_Country_Code: 'IND', Two_Letter_Country_Code: 'IN' },
  { Continent_Code: 'AS', Continent_Name: 'Asia', Country_Name: 'Indonesia', Country_Number: 360, Three_Letter_Country_Code: 'IDN', Two_Letter_Country_Code: 'ID' },
  { Continent_Code: 'AS', Continent_Name: 'Asia', Country_Name: 'Iran', Country_Number: 364, Three_Letter_Country_Code: 'IRN', Two_Letter_Country_Code: 'IR' },
  { Continent_Code: 'AS', Continent_Name: 'Asia', Country_Name: 'Iraq', Country_Number: 368, Three_Letter_Country_Code: 'IRQ', Two_Letter_Country_Code: 'IQ' },
  { Continent_Code: 'EU', Continent_Name: 'Europe', Country_Name: 'Ireland', Country_Number: 372, Three_Letter_Country_Code: 'IRL', Two_Letter_Country_Code: 'IE' },
  { Continent_Code: 'AS', Continent_Name: 'Asia', Country_Name: 'Israel', Country_Number: 376, Three_Letter_Country_Code: 'ISR', Two_Letter_Country_Code: 'IL' },
  { Continent_Code: 'EU', Continent_Name: 'Europe', Country_Name: 'Italy', Country_Number: 380, Three_Letter_Country_Code: 'ITA', Two_Letter_Country_Code: 'IT' },
  { Continent_Code: 'AF', Continent_Name: 'Africa', Country_Name: "Cote d'Ivoire", Country_Number: 384, Three_Letter_Country_Code: 'CIV', Two_Letter_Country_Code: 'CI' },
  { Continent_Code: 'NA', Continent_Name: 'North America', Country_Name: 'Jamaica', Country_Number: 388, Three_Letter_Country_Code: 'JAM', Two_Letter_Country_Code: 'JM' },
  { Continent_Code: 'AS', Continent_Name: 'Asia', Country_Name: 'Japan', Country_Number: 392, Three_Letter_Country_Code: 'JPN', Two_Letter_Country_Code: 'JP' },
  { Continent_Code: 'EU', Continent_Name: 'Europe', Country_Name: 'Kazakhstan', Country_Number: 398, Three_Letter_Country_Code: 'KAZ', Two_Letter_Country_Code: 'KZ' },
  { Continent_Code: 'AS', Continent_Name: 'Asia', Country_Name: 'Kazakhstan', Country_Number: 398, Three_Letter_Country_Code: 'KAZ', Two_Letter_Country_Code: 'KZ' },
  { Continent_Code: 'AS', Continent_Name: 'Asia', Country_Name: 'Jordan', Country_Number: 400, Three_Letter_Country_Code: 'JOR', Two_Letter_Country_Code: 'JO' },
  { Continent_Code: 'AF', Continent_Name: 'Africa', Country_Name: 'Kenya', Country_Number: 404, Three_Letter_Country_Code: 'KEN', Two_Letter_Country_Code: 'KE' },
  {
    Continent_Code: 'AS',
    Continent_Name: 'Asia',
    Country_Name: "Korea, Democratic People's Republic of",
    Country_Number: 408,
    Three_Letter_Country_Code: 'PRK',
    Two_Letter_Country_Code: 'KP'
  },
  { Continent_Code: 'AS', Continent_Name: 'Asia', Country_Name: 'Korea', Country_Number: 410, Three_Letter_Country_Code: 'KOR', Two_Letter_Country_Code: 'KR' },
  { Continent_Code: 'AS', Continent_Name: 'Asia', Country_Name: 'Kuwait', Country_Number: 414, Three_Letter_Country_Code: 'KWT', Two_Letter_Country_Code: 'KW' },
  { Continent_Code: 'AS', Continent_Name: 'Asia', Country_Name: 'Kyrgyz Republic', Country_Number: 417, Three_Letter_Country_Code: 'KGZ', Two_Letter_Country_Code: 'KG' },
  { Continent_Code: 'AS', Continent_Name: 'Asia', Country_Name: "Lao People's Democratic Republic", Country_Number: 418, Three_Letter_Country_Code: 'LAO', Two_Letter_Country_Code: 'LA' },
  { Continent_Code: 'AS', Continent_Name: 'Asia', Country_Name: 'Lebanon', Country_Number: 422, Three_Letter_Country_Code: 'LBN', Two_Letter_Country_Code: 'LB' },
  { Continent_Code: 'AF', Continent_Name: 'Africa', Country_Name: 'Lesotho', Country_Number: 426, Three_Letter_Country_Code: 'LSO', Two_Letter_Country_Code: 'LS' },
  { Continent_Code: 'EU', Continent_Name: 'Europe', Country_Name: 'Latvia', Country_Number: 428, Three_Letter_Country_Code: 'LVA', Two_Letter_Country_Code: 'LV' },
  { Continent_Code: 'AF', Continent_Name: 'Africa', Country_Name: 'Liberia', Country_Number: 430, Three_Letter_Country_Code: 'LBR', Two_Letter_Country_Code: 'LR' },
  { Continent_Code: 'AF', Continent_Name: 'Africa', Country_Name: 'Libyan Arab Jamahiriya', Country_Number: 434, Three_Letter_Country_Code: 'LBY', Two_Letter_Country_Code: 'LY' },
  { Continent_Code: 'EU', Continent_Name: 'Europe', Country_Name: 'Liechtenstein', Country_Number: 438, Three_Letter_Country_Code: 'LIE', Two_Letter_Country_Code: 'LI' },
  { Continent_Code: 'EU', Continent_Name: 'Europe', Country_Name: 'Lithuania', Country_Number: 440, Three_Letter_Country_Code: 'LTU', Two_Letter_Country_Code: 'LT' },
  { Continent_Code: 'EU', Continent_Name: 'Europe', Country_Name: 'Luxembourg', Country_Number: 442, Three_Letter_Country_Code: 'LUX', Two_Letter_Country_Code: 'LU' },
  {
    Continent_Code: 'AS',
    Continent_Name: 'Asia',
    Country_Name: 'Macao',
    Country_Number: 446,
    Three_Letter_Country_Code: 'MAC',
    Two_Letter_Country_Code: 'MO'
  },
  { Continent_Code: 'AF', Continent_Name: 'Africa', Country_Name: 'Madagascar', Country_Number: 450, Three_Letter_Country_Code: 'MDG', Two_Letter_Country_Code: 'MG' },
  { Continent_Code: 'AF', Continent_Name: 'Africa', Country_Name: 'Malawi', Country_Number: 454, Three_Letter_Country_Code: 'MWI', Two_Letter_Country_Code: 'MW' },
  { Continent_Code: 'AS', Continent_Name: 'Asia', Country_Name: 'Malaysia', Country_Number: 458, Three_Letter_Country_Code: 'MYS', Two_Letter_Country_Code: 'MY' },
  { Continent_Code: 'AS', Continent_Name: 'Asia', Country_Name: 'Maldives', Country_Number: 462, Three_Letter_Country_Code: 'MDV', Two_Letter_Country_Code: 'MV' },
  { Continent_Code: 'AF', Continent_Name: 'Africa', Country_Name: 'Mali', Country_Number: 466, Three_Letter_Country_Code: 'MLI', Two_Letter_Country_Code: 'ML' },
  { Continent_Code: 'EU', Continent_Name: 'Europe', Country_Name: 'Malta', Country_Number: 470, Three_Letter_Country_Code: 'MLT', Two_Letter_Country_Code: 'MT' },
  { Continent_Code: 'NA', Continent_Name: 'North America', Country_Name: 'Martinique', Country_Number: 474, Three_Letter_Country_Code: 'MTQ', Two_Letter_Country_Code: 'MQ' },
  { Continent_Code: 'AF', Continent_Name: 'Africa', Country_Name: 'Mauritania', Country_Number: 478, Three_Letter_Country_Code: 'MRT', Two_Letter_Country_Code: 'MR' },
  { Continent_Code: 'AF', Continent_Name: 'Africa', Country_Name: 'Mauritius', Country_Number: 480, Three_Letter_Country_Code: 'MUS', Two_Letter_Country_Code: 'MU' },
  {
    Continent_Code: 'NA',
    Continent_Name: 'North America',
    Country_Name: 'Mexico',
    Country_Number: 484,
    Three_Letter_Country_Code: 'MEX',
    Two_Letter_Country_Code: 'MX'
  },
  { Continent_Code: 'EU', Continent_Name: 'Europe', Country_Name: 'Monaco', Country_Number: 492, Three_Letter_Country_Code: 'MCO', Two_Letter_Country_Code: 'MC' },
  { Continent_Code: 'AS', Continent_Name: 'Asia', Country_Name: 'Mongolia', Country_Number: 496, Three_Letter_Country_Code: 'MNG', Two_Letter_Country_Code: 'MN' },
  { Continent_Code: 'EU', Continent_Name: 'Europe', Country_Name: 'Moldova', Country_Number: 498, Three_Letter_Country_Code: 'MDA', Two_Letter_Country_Code: 'MD' },
  { Continent_Code: 'EU', Continent_Name: 'Europe', Country_Name: 'Montenegro', Country_Number: 499, Three_Letter_Country_Code: 'MNE', Two_Letter_Country_Code: 'ME' },
  { Continent_Code: 'NA', Continent_Name: 'North America', Country_Name: 'Montserrat', Country_Number: 500, Three_Letter_Country_Code: 'MSR', Two_Letter_Country_Code: 'MS' },
  { Continent_Code: 'AF', Continent_Name: 'Africa', Country_Name: 'Morocco', Country_Number: 504, Three_Letter_Country_Code: 'MAR', Two_Letter_Country_Code: 'MA' },
  { Continent_Code: 'AF', Continent_Name: 'Africa', Country_Name: 'Mozambique', Country_Number: 508, Three_Letter_Country_Code: 'MOZ', Two_Letter_Country_Code: 'MZ' },
  { Continent_Code: 'AS', Continent_Name: 'Asia', Country_Name: 'Oman', Country_Number: 512, Three_Letter_Country_Code: 'OMN', Two_Letter_Country_Code: 'OM' },
  { Continent_Code: 'AF', Continent_Name: 'Africa', Country_Name: 'Namibia', Country_Number: 516, Three_Letter_Country_Code: 'NAM', Two_Letter_Country_Code: 'NA' },
  { Continent_Code: 'OC', Continent_Name: 'Oceania', Country_Name: 'Nauru', Country_Number: 520, Three_Letter_Country_Code: 'NRU', Two_Letter_Country_Code: 'NR' },
  { Continent_Code: 'AS', Continent_Name: 'Asia', Country_Name: 'Nepal', Country_Number: 524, Three_Letter_Country_Code: 'NPL', Two_Letter_Country_Code: 'NP' },
  { Continent_Code: 'EU', Continent_Name: 'Europe', Country_Name: 'Netherlands the', Country_Number: 528, Three_Letter_Country_Code: 'NLD', Two_Letter_Country_Code: 'NL' },
  { Continent_Code: 'NA', Continent_Name: 'North America', Country_Name: 'Netherlands Antilles', Country_Number: 530, Three_Letter_Country_Code: 'ANT', Two_Letter_Country_Code: 'AN' },
  { Continent_Code: 'NA', Continent_Name: 'North America', Country_Name: 'Cura\u00e7ao', Country_Number: 531, Three_Letter_Country_Code: 'CUW', Two_Letter_Country_Code: 'CW' },
  { Continent_Code: 'NA', Continent_Name: 'North America', Country_Name: 'Aruba', Country_Number: 533, Three_Letter_Country_Code: 'ABW', Two_Letter_Country_Code: 'AW' },
  {
    Continent_Code: 'NA',
    Continent_Name: 'North America',
    Country_Name: 'Sint Maarten (Netherlands)',
    Country_Number: 534,
    Three_Letter_Country_Code: 'SXM',
    Two_Letter_Country_Code: 'SX'
  },
  {
    Continent_Code: 'NA',
    Continent_Name: 'North America',
    Country_Name: 'Bonaire, Sint Eustatius and Saba',
    Country_Number: 535,
    Three_Letter_Country_Code: 'BES',
    Two_Letter_Country_Code: 'BQ'
  },
  { Continent_Code: 'OC', Continent_Name: 'Oceania', Country_Name: 'New Caledonia', Country_Number: 540, Three_Letter_Country_Code: 'NCL', Two_Letter_Country_Code: 'NC' },
  { Continent_Code: 'OC', Continent_Name: 'Oceania', Country_Name: 'Vanuatu', Country_Number: 548, Three_Letter_Country_Code: 'VUT', Two_Letter_Country_Code: 'VU' },
  { Continent_Code: 'OC', Continent_Name: 'Oceania', Country_Name: 'New Zealand', Country_Number: 554, Three_Letter_Country_Code: 'NZL', Two_Letter_Country_Code: 'NZ' },
  { Continent_Code: 'NA', Continent_Name: 'North America', Country_Name: 'Nicaragua', Country_Number: 558, Three_Letter_Country_Code: 'NIC', Two_Letter_Country_Code: 'NI' },
  { Continent_Code: 'AF', Continent_Name: 'Africa', Country_Name: 'Niger', Country_Number: 562, Three_Letter_Country_Code: 'NER', Two_Letter_Country_Code: 'NE' },
  { Continent_Code: 'AF', Continent_Name: 'Africa', Country_Name: 'Nigeria', Country_Number: 566, Three_Letter_Country_Code: 'NGA', Two_Letter_Country_Code: 'NG' },
  { Continent_Code: 'OC', Continent_Name: 'Oceania', Country_Name: 'Niue', Country_Number: 570, Three_Letter_Country_Code: 'NIU', Two_Letter_Country_Code: 'NU' },
  { Continent_Code: 'OC', Continent_Name: 'Oceania', Country_Name: 'Norfolk Island', Country_Number: 574, Three_Letter_Country_Code: 'NFK', Two_Letter_Country_Code: 'NF' },
  { Continent_Code: 'EU', Continent_Name: 'Europe', Country_Name: 'Norway', Country_Number: 578, Three_Letter_Country_Code: 'NOR', Two_Letter_Country_Code: 'NO' },
  {
    Continent_Code: 'OC',
    Continent_Name: 'Oceania',
    Country_Name: 'Northern Mariana Islands the',
    Country_Number: 580,
    Three_Letter_Country_Code: 'MNP',
    Two_Letter_Country_Code: 'MP'
  },
  {
    Continent_Code: 'OC',
    Continent_Name: 'Oceania',
    Country_Name: 'United States Minor Outlying Islands',
    Country_Number: 581,
    Three_Letter_Country_Code: 'UMI',
    Two_Letter_Country_Code: 'UM'
  },
  {
    Continent_Code: 'NA',
    Continent_Name: 'North America',
    Country_Name: 'United States Minor Outlying Islands',
    Country_Number: 581,
    Three_Letter_Country_Code: 'UMI',
    Two_Letter_Country_Code: 'UM'
  },
  {
    Continent_Code: 'OC',
    Continent_Name: 'Oceania',
    Country_Name: 'Micronesia, Federated States of',
    Country_Number: 583,
    Three_Letter_Country_Code: 'FSM',
    Two_Letter_Country_Code: 'FM'
  },
  {
    Continent_Code: 'OC',
    Continent_Name: 'Oceania',
    Country_Name: 'Marshall Islands',
    Country_Number: 584,
    Three_Letter_Country_Code: 'MHL',
    Two_Letter_Country_Code: 'MH'
  },
  { Continent_Code: 'OC', Continent_Name: 'Oceania', Country_Name: 'Palau', Country_Number: 585, Three_Letter_Country_Code: 'PLW', Two_Letter_Country_Code: 'PW' },
  { Continent_Code: 'AS', Continent_Name: 'Asia', Country_Name: 'Pakistan', Country_Number: 586, Three_Letter_Country_Code: 'PAK', Two_Letter_Country_Code: 'PK' },
  { Continent_Code: 'NA', Continent_Name: 'North America', Country_Name: 'Panama', Country_Number: 591, Three_Letter_Country_Code: 'PAN', Two_Letter_Country_Code: 'PA' },
  {
    Continent_Code: 'OC',
    Continent_Name: 'Oceania',
    Country_Name: 'Papua New Guinea',
    Country_Number: 598,
    Three_Letter_Country_Code: 'PNG',
    Two_Letter_Country_Code: 'PG'
  },
  { Continent_Code: 'SA', Continent_Name: 'South America', Country_Name: 'Paraguay', Country_Number: 600, Three_Letter_Country_Code: 'PRY', Two_Letter_Country_Code: 'PY' },
  { Continent_Code: 'SA', Continent_Name: 'South America', Country_Name: 'Peru', Country_Number: 604, Three_Letter_Country_Code: 'PER', Two_Letter_Country_Code: 'PE' },
  { Continent_Code: 'AS', Continent_Name: 'Asia', Country_Name: 'Philippines', Country_Number: 608, Three_Letter_Country_Code: 'PHL', Two_Letter_Country_Code: 'PH' },
  { Continent_Code: 'OC', Continent_Name: 'Oceania', Country_Name: 'Pitcairn Islands', Country_Number: 612, Three_Letter_Country_Code: 'PCN', Two_Letter_Country_Code: 'PN' },
  { Continent_Code: 'EU', Continent_Name: 'Europe', Country_Name: 'Poland', Country_Number: 616, Three_Letter_Country_Code: 'POL', Two_Letter_Country_Code: 'PL' },
  { Continent_Code: 'EU', Continent_Name: 'Europe', Country_Name: 'Portugal', Country_Number: 620, Three_Letter_Country_Code: 'PRT', Two_Letter_Country_Code: 'PT' },
  { Continent_Code: 'AF', Continent_Name: 'Africa', Country_Name: 'Guinea-Bissau', Country_Number: 624, Three_Letter_Country_Code: 'GNB', Two_Letter_Country_Code: 'GW' },
  {
    Continent_Code: 'AS',
    Continent_Name: 'Asia',
    Country_Name: 'Timor-Leste',
    Country_Number: 626,
    Three_Letter_Country_Code: 'TLS',
    Two_Letter_Country_Code: 'TL'
  },
  {
    Continent_Code: 'NA',
    Continent_Name: 'North America',
    Country_Name: 'Puerto Rico',
    Country_Number: 630,
    Three_Letter_Country_Code: 'PRI',
    Two_Letter_Country_Code: 'PR'
  },
  { Continent_Code: 'AS', Continent_Name: 'Asia', Country_Name: 'Qatar', Country_Number: 634, Three_Letter_Country_Code: 'QAT', Two_Letter_Country_Code: 'QA' },
  { Continent_Code: 'AF', Continent_Name: 'Africa', Country_Name: 'Reunion', Country_Number: 638, Three_Letter_Country_Code: 'REU', Two_Letter_Country_Code: 'RE' },
  { Continent_Code: 'EU', Continent_Name: 'Europe', Country_Name: 'Romania', Country_Number: 642, Three_Letter_Country_Code: 'ROU', Two_Letter_Country_Code: 'RO' },
  { Continent_Code: 'EU', Continent_Name: 'Europe', Country_Name: 'Russian Federation', Country_Number: 643, Three_Letter_Country_Code: 'RUS', Two_Letter_Country_Code: 'RU' },
  { Continent_Code: 'AS', Continent_Name: 'Asia', Country_Name: 'Russian Federation', Country_Number: 643, Three_Letter_Country_Code: 'RUS', Two_Letter_Country_Code: 'RU' },
  { Continent_Code: 'AF', Continent_Name: 'Africa', Country_Name: 'Rwanda', Country_Number: 646, Three_Letter_Country_Code: 'RWA', Two_Letter_Country_Code: 'RW' },
  { Continent_Code: 'NA', Continent_Name: 'North America', Country_Name: 'Saint Barthelemy', Country_Number: 652, Three_Letter_Country_Code: 'BLM', Two_Letter_Country_Code: 'BL' },
  { Continent_Code: 'AF', Continent_Name: 'Africa', Country_Name: 'Saint Helena', Country_Number: 654, Three_Letter_Country_Code: 'SHN', Two_Letter_Country_Code: 'SH' },
  {
    Continent_Code: 'NA',
    Continent_Name: 'North America',
    Country_Name: 'Saint Kitts and Nevis, Federation of',
    Country_Number: 659,
    Three_Letter_Country_Code: 'KNA',
    Two_Letter_Country_Code: 'KN'
  },
  { Continent_Code: 'NA', Continent_Name: 'North America', Country_Name: 'Anguilla', Country_Number: 660, Three_Letter_Country_Code: 'AIA', Two_Letter_Country_Code: 'AI' },
  { Continent_Code: 'NA', Continent_Name: 'North America', Country_Name: 'Saint Lucia', Country_Number: 662, Three_Letter_Country_Code: 'LCA', Two_Letter_Country_Code: 'LC' },
  { Continent_Code: 'NA', Continent_Name: 'North America', Country_Name: 'Saint Martin', Country_Number: 663, Three_Letter_Country_Code: 'MAF', Two_Letter_Country_Code: 'MF' },
  {
    Continent_Code: 'NA',
    Continent_Name: 'North America',
    Country_Name: 'Saint Pierre and Miquelon',
    Country_Number: 666,
    Three_Letter_Country_Code: 'SPM',
    Two_Letter_Country_Code: 'PM'
  },
  {
    Continent_Code: 'NA',
    Continent_Name: 'North America',
    Country_Name: 'Saint Vincent and the Grenadines',
    Country_Number: 670,
    Three_Letter_Country_Code: 'VCT',
    Two_Letter_Country_Code: 'VC'
  },
  { Continent_Code: 'EU', Continent_Name: 'Europe', Country_Name: 'San Marino', Country_Number: 674, Three_Letter_Country_Code: 'SMR', Two_Letter_Country_Code: 'SM' },
  {
    Continent_Code: 'AF',
    Continent_Name: 'Africa',
    Country_Name: 'Sao Tome and Principe',
    Country_Number: 678,
    Three_Letter_Country_Code: 'STP',
    Two_Letter_Country_Code: 'ST'
  },
  { Continent_Code: 'AS', Continent_Name: 'Asia', Country_Name: 'Saudi Arabia', Country_Number: 682, Three_Letter_Country_Code: 'SAU', Two_Letter_Country_Code: 'SA' },
  { Continent_Code: 'AF', Continent_Name: 'Africa', Country_Name: 'Senegal', Country_Number: 686, Three_Letter_Country_Code: 'SEN', Two_Letter_Country_Code: 'SN' },
  { Continent_Code: 'EU', Continent_Name: 'Europe', Country_Name: 'Serbia', Country_Number: 688, Three_Letter_Country_Code: 'SRB', Two_Letter_Country_Code: 'RS' },
  { Continent_Code: 'AF', Continent_Name: 'Africa', Country_Name: 'Seychelles', Country_Number: 690, Three_Letter_Country_Code: 'SYC', Two_Letter_Country_Code: 'SC' },
  { Continent_Code: 'AF', Continent_Name: 'Africa', Country_Name: 'Sierra Leone', Country_Number: 694, Three_Letter_Country_Code: 'SLE', Two_Letter_Country_Code: 'SL' },
  { Continent_Code: 'AS', Continent_Name: 'Asia', Country_Name: 'Singapore', Country_Number: 702, Three_Letter_Country_Code: 'SGP', Two_Letter_Country_Code: 'SG' },
  { Continent_Code: 'EU', Continent_Name: 'Europe', Country_Name: 'Slovakia', Country_Number: 703, Three_Letter_Country_Code: 'SVK', Two_Letter_Country_Code: 'SK' },
  { Continent_Code: 'AS', Continent_Name: 'Asia', Country_Name: 'Vietnam', Country_Number: 704, Three_Letter_Country_Code: 'VNM', Two_Letter_Country_Code: 'VN' },
  { Continent_Code: 'EU', Continent_Name: 'Europe', Country_Name: 'Slovenia', Country_Number: 705, Three_Letter_Country_Code: 'SVN', Two_Letter_Country_Code: 'SI' },
  { Continent_Code: 'AF', Continent_Name: 'Africa', Country_Name: 'Somalia', Country_Number: 706, Three_Letter_Country_Code: 'SOM', Two_Letter_Country_Code: 'SO' },
  { Continent_Code: 'AF', Continent_Name: 'Africa', Country_Name: 'South Africa', Country_Number: 710, Three_Letter_Country_Code: 'ZAF', Two_Letter_Country_Code: 'ZA' },
  { Continent_Code: 'AF', Continent_Name: 'Africa', Country_Name: 'Zimbabwe', Country_Number: 716, Three_Letter_Country_Code: 'ZWE', Two_Letter_Country_Code: 'ZW' },
  { Continent_Code: 'EU', Continent_Name: 'Europe', Country_Name: 'Spain', Country_Number: 724, Three_Letter_Country_Code: 'ESP', Two_Letter_Country_Code: 'ES' },
  { Continent_Code: 'AF', Continent_Name: 'Africa', Country_Name: 'South Sudan', Country_Number: 728, Three_Letter_Country_Code: 'SSD', Two_Letter_Country_Code: 'SS' },
  { Continent_Code: 'AF', Continent_Name: 'Africa', Country_Name: 'Western Sahara', Country_Number: 732, Three_Letter_Country_Code: 'ESH', Two_Letter_Country_Code: 'EH' },
  { Continent_Code: 'AF', Continent_Name: 'Africa', Country_Name: 'Sudan', Country_Number: 736, Three_Letter_Country_Code: 'SDN', Two_Letter_Country_Code: 'SD' },
  { Continent_Code: 'SA', Continent_Name: 'South America', Country_Name: 'Suriname', Country_Number: 740, Three_Letter_Country_Code: 'SUR', Two_Letter_Country_Code: 'SR' },
  { Continent_Code: 'EU', Continent_Name: 'Europe', Country_Name: 'Svalbard & Jan Mayen Islands', Country_Number: 744, Three_Letter_Country_Code: 'SJM', Two_Letter_Country_Code: 'SJ' },
  { Continent_Code: 'AF', Continent_Name: 'Africa', Country_Name: 'Swaziland', Country_Number: 748, Three_Letter_Country_Code: 'SWZ', Two_Letter_Country_Code: 'SZ' },
  { Continent_Code: 'EU', Continent_Name: 'Europe', Country_Name: 'Sweden', Country_Number: 752, Three_Letter_Country_Code: 'SWE', Two_Letter_Country_Code: 'SE' },
  {
    Continent_Code: 'EU',
    Continent_Name: 'Europe',
    Country_Name: 'Switzerland',
    Country_Number: 756,
    Three_Letter_Country_Code: 'CHE',
    Two_Letter_Country_Code: 'CH'
  },
  { Continent_Code: 'AS', Continent_Name: 'Asia', Country_Name: 'Syrian Arab Republic', Country_Number: 760, Three_Letter_Country_Code: 'SYR', Two_Letter_Country_Code: 'SY' },
  { Continent_Code: 'AS', Continent_Name: 'Asia', Country_Name: 'Tajikistan', Country_Number: 762, Three_Letter_Country_Code: 'TJK', Two_Letter_Country_Code: 'TJ' },
  { Continent_Code: 'AS', Continent_Name: 'Asia', Country_Name: 'Thailand', Country_Number: 764, Three_Letter_Country_Code: 'THA', Two_Letter_Country_Code: 'TH' },
  { Continent_Code: 'AF', Continent_Name: 'Africa', Country_Name: 'Togo', Country_Number: 768, Three_Letter_Country_Code: 'TGO', Two_Letter_Country_Code: 'TG' },
  { Continent_Code: 'OC', Continent_Name: 'Oceania', Country_Name: 'Tokelau', Country_Number: 772, Three_Letter_Country_Code: 'TKL', Two_Letter_Country_Code: 'TK' },
  { Continent_Code: 'OC', Continent_Name: 'Oceania', Country_Name: 'Tonga', Country_Number: 776, Three_Letter_Country_Code: 'TON', Two_Letter_Country_Code: 'TO' },
  {
    Continent_Code: 'NA',
    Continent_Name: 'North America',
    Country_Name: 'Trinidad and Tobago',
    Country_Number: 780,
    Three_Letter_Country_Code: 'TTO',
    Two_Letter_Country_Code: 'TT'
  },
  { Continent_Code: 'AS', Continent_Name: 'Asia', Country_Name: 'United Arab Emirates', Country_Number: 784, Three_Letter_Country_Code: 'ARE', Two_Letter_Country_Code: 'AE' },
  { Continent_Code: 'AF', Continent_Name: 'Africa', Country_Name: 'Tunisia', Country_Number: 788, Three_Letter_Country_Code: 'TUN', Two_Letter_Country_Code: 'TN' },
  { Continent_Code: 'EU', Continent_Name: 'Europe', Country_Name: 'Turkey', Country_Number: 792, Three_Letter_Country_Code: 'TUR', Two_Letter_Country_Code: 'TR' },
  { Continent_Code: 'AS', Continent_Name: 'Asia', Country_Name: 'Turkey', Country_Number: 792, Three_Letter_Country_Code: 'TUR', Two_Letter_Country_Code: 'TR' },
  { Continent_Code: 'AS', Continent_Name: 'Asia', Country_Name: 'Turkmenistan', Country_Number: 795, Three_Letter_Country_Code: 'TKM', Two_Letter_Country_Code: 'TM' },
  { Continent_Code: 'NA', Continent_Name: 'North America', Country_Name: 'Turks and Caicos Islands', Country_Number: 796, Three_Letter_Country_Code: 'TCA', Two_Letter_Country_Code: 'TC' },
  { Continent_Code: 'OC', Continent_Name: 'Oceania', Country_Name: 'Tuvalu', Country_Number: 798, Three_Letter_Country_Code: 'TUV', Two_Letter_Country_Code: 'TV' },
  { Continent_Code: 'AF', Continent_Name: 'Africa', Country_Name: 'Uganda', Country_Number: 800, Three_Letter_Country_Code: 'UGA', Two_Letter_Country_Code: 'UG' },
  { Continent_Code: 'EU', Continent_Name: 'Europe', Country_Name: 'Ukraine', Country_Number: 804, Three_Letter_Country_Code: 'UKR', Two_Letter_Country_Code: 'UA' },
  {
    Continent_Code: 'EU',
    Continent_Name: 'Europe',
    Country_Name: 'Macedonia',
    Country_Number: 807,
    Three_Letter_Country_Code: 'MKD',
    Two_Letter_Country_Code: 'MK'
  },
  { Continent_Code: 'AF', Continent_Name: 'Africa', Country_Name: 'Egypt', Country_Number: 818, Three_Letter_Country_Code: 'EGY', Two_Letter_Country_Code: 'EG' },
  {
    Continent_Code: 'EU',
    Continent_Name: 'Europe',
    Country_Name: 'United Kingdom',
    Country_Number: 826,
    Three_Letter_Country_Code: 'GBR',
    Two_Letter_Country_Code: 'GB'
  },
  { Continent_Code: 'EU', Continent_Name: 'Europe', Country_Name: 'Guernsey', Country_Number: 831, Three_Letter_Country_Code: 'GGY', Two_Letter_Country_Code: 'GG' },
  { Continent_Code: 'EU', Continent_Name: 'Europe', Country_Name: 'Jersey', Country_Number: 832, Three_Letter_Country_Code: 'JEY', Two_Letter_Country_Code: 'JE' },
  { Continent_Code: 'EU', Continent_Name: 'Europe', Country_Name: 'Isle of Man', Country_Number: 833, Three_Letter_Country_Code: 'IMN', Two_Letter_Country_Code: 'IM' },
  { Continent_Code: 'AF', Continent_Name: 'Africa', Country_Name: 'Tanzania', Country_Number: 834, Three_Letter_Country_Code: 'TZA', Two_Letter_Country_Code: 'TZ' },
  { Continent_Code: 'NA', Continent_Name: 'North America', Country_Name: 'United States of America', Country_Number: 840, Three_Letter_Country_Code: 'USA', Two_Letter_Country_Code: 'US' },
  {
    Continent_Code: 'NA',
    Continent_Name: 'North America',
    Country_Name: 'United States Virgin Islands',
    Country_Number: 850,
    Three_Letter_Country_Code: 'VIR',
    Two_Letter_Country_Code: 'VI'
  },
  { Continent_Code: 'AF', Continent_Name: 'Africa', Country_Name: 'Burkina Faso', Country_Number: 854, Three_Letter_Country_Code: 'BFA', Two_Letter_Country_Code: 'BF' },
  {
    Continent_Code: 'SA',
    Continent_Name: 'South America',
    Country_Name: 'Uruguay',
    Country_Number: 858,
    Three_Letter_Country_Code: 'URY',
    Two_Letter_Country_Code: 'UY'
  },
  { Continent_Code: 'AS', Continent_Name: 'Asia', Country_Name: 'Uzbekistan', Country_Number: 860, Three_Letter_Country_Code: 'UZB', Two_Letter_Country_Code: 'UZ' },
  {
    Continent_Code: 'SA',
    Continent_Name: 'South America',
    Country_Name: 'Venezuela',
    Country_Number: 862,
    Three_Letter_Country_Code: 'VEN',
    Two_Letter_Country_Code: 'VE'
  },
  { Continent_Code: 'OC', Continent_Name: 'Oceania', Country_Name: 'Wallis and Futuna', Country_Number: 876, Three_Letter_Country_Code: 'WLF', Two_Letter_Country_Code: 'WF' },
  { Continent_Code: 'OC', Continent_Name: 'Oceania', Country_Name: 'Samoa', Country_Number: 882, Three_Letter_Country_Code: 'WSM', Two_Letter_Country_Code: 'WS' },
  { Continent_Code: 'AS', Continent_Name: 'Asia', Country_Name: 'Yemen', Country_Number: 887, Three_Letter_Country_Code: 'YEM', Two_Letter_Country_Code: 'YE' },
  { Continent_Code: 'AF', Continent_Name: 'Africa', Country_Name: 'Zambia', Country_Number: 894, Three_Letter_Country_Code: 'ZMB', Two_Letter_Country_Code: 'ZM' },
  { Continent_Code: 'OC', Continent_Name: 'Oceania', Country_Name: 'Disputed Territory', Country_Number: null, Three_Letter_Country_Code: null, Two_Letter_Country_Code: 'XX' },
  { Continent_Code: 'AS', Continent_Name: 'Asia', Country_Name: 'Iraq-Saudi Arabia Neutral Zone', Country_Number: null, Three_Letter_Country_Code: null, Two_Letter_Country_Code: 'XE' },
  { Continent_Code: 'AS', Continent_Name: 'Asia', Country_Name: 'United Nations Neutral Zone', Country_Number: null, Three_Letter_Country_Code: null, Two_Letter_Country_Code: 'XD' },
  { Continent_Code: 'AS', Continent_Name: 'Asia', Country_Name: 'Spratly Islands', Country_Number: null, Three_Letter_Country_Code: null, Two_Letter_Country_Code: 'XS' }
];

export default list;
